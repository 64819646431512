<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6 header-body">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col-3">
                            <h3 class="mb-0">{{ tt('task_tickets') }}</h3>
                        </div>
                        <div class="col-5">
                            <!-- <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')" v-on:keyup="filter"/> -->
                        </div>
                        <div class="col-4 text-right">
                            <router-link :to="{ name: 'My Task Show', params: { id: this.params.id }}">
                                <button type="button" class="btn btn-default btn-sm mr-2">{{ tt('waiting') }}</button>
                            </router-link>
                            <router-link :to="{ name: 'My Task Closed', params: { id: this.params.id }}">
                                <button type="button" class="btn btn-white btn-sm">{{ tt('closed') }}</button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table ref="multipleTable" :data="table.data" header-row-class-name="thead-light" class="table-responsive table-flush" style="width: 100%" v-if="!onLoad.table" highlight-current-row height="500px" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55" v-if="authUserPermission['mcr-approval-multi-revise'] || authUserPermission['mcr-approval-multi-reject'] || authUserPermission['mcr-approval-multi-approve']"></el-table-column>
                        <el-table-column :label="tt('description')" :prop="tt('description')" min-width="250px">
                            <template slot="header" slot-scope="scope">
                                <div class="row">
                                    <div class="col-12" style="text-align:center;">
                                        {{ tt('description') }}
                                    </div>
                                    <div class="col-12">
                                        <el-input  class="mb-2" v-model="table.search.description" size="mini" @input="filter"></el-input>
                                    </div>
                                </div>
                            </template>
                            <template v-slot="{row}">
                                {{row.description}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" v-if="taskCode == 'EQUIPMENT'">
                            <template slot="header" slot-scope="scope">
                                <div class="row">
                                    <div class="col-12" style="text-align:center;">
                                        {{ tt('material_number') }}
                                    </div>
                                    <div class="col-12">
                                        <el-input  class="mb-2" v-model="table.search.material_number" size="mini" @input="filter"></el-input>
                                    </div>
                                </div>
                            </template>
                            <template v-slot="{row}">
                                {{row.material_number}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('equipment')" :prop="tt('equipment')" min-width="400px" v-if="taskCode == 'EQUIPMENT'">
                            <template slot="header" slot-scope="scope">
                                <div class="row">
                                    <div class="col-12" style="text-align:center;">
                                        {{ tt('equipment') }}
                                    </div>
                                    <div class="col-12">
                                        <el-input  class="mb-2" v-model="table.search.equipment_code" size="mini" @input="filter"></el-input>
                                    </div>
                                </div>
                            </template>
                            <template v-slot="{row}">
                                {{row.equipment_code +' - '+ row.equipment_name}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('qty_installed')" :prop="tt('qty_installed')" min-width="180px" v-if="taskCode == 'EQUIPMENT'">
                             <template slot="header" slot-scope="scope">
                                <div class="row">
                                    <div class="col-12" style="text-align:center;">
                                        {{ tt('qty_installed') }}
                                    </div>
                                    <div class="col-12">
                                        <el-input  class="mb-2" v-model="table.search.qty_installed" size="mini" @input="filter"></el-input>
                                    </div>
                                </div>
                            </template>
                            <template v-slot="{row}">
                                {{row.qty_installed}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('requestor')" :prop="tt('name')" min-width="200px">
                             <template slot="header" slot-scope="scope">
                                <div class="row">
                                    <div class="col-12" style="text-align:center;">
                                        {{ tt('requestor') }}
                                    </div>
                                    <div class="col-12">
                                        <el-input  class="mb-2" v-model="table.search.requestor" size="mini" @input="filter"></el-input>
                                    </div>
                                </div>
                            </template>
                            <template v-slot="{row}">
                                {{ row.name }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('assigned_date')" :prop="tt('assigned_date')" min-width="200px">
                            <template slot="header" slot-scope="scope">
                                <div class="row">
                                    <div class="col-12" style="text-align:center;">
                                        {{ tt('assigned_date') }}
                                    </div>
                                    <div class="col-12">
                                        <el-input  class="mb-2" v-model="table.search.assigned_date" size="mini" @input="filter" type="date" value-format="Y-m-d"></el-input>
                                    </div>
                                </div>
                            </template>
                            <template v-slot="{row}">
                                {{ row.assigned_date }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('count_days')" :prop="tt('count_days')" min-width="200px">
                             <template slot="header" slot-scope="scope">
                                <div class="row">
                                    <div class="col-12" style="text-align:center;">
                                        {{ tt('count_days') }}
                                    </div>
                                    <div class="col-12">
                                        <el-input  class="mb-2" size="mini" disabled></el-input>
                                    </div>
                                </div>
                            </template>
                            <template v-slot="{row}">
                                {{ moment(row.assigned_date).locale('en').fromNow() }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" :hide-on-click="true" @command="handleTableAction">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action: row.process_target,url: row.process_url}">{{ tt("process") }}</el-dropdown-item> 
                                        <el-dropdown-item :command="{action: 'tracking',data:{mcr_code:row.key}}">{{ tt("tracking") }}</el-dropdown-item> 
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                    <skeleton-loading v-else/>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>

            <McrFooter :multipleKey="multipleKey" v-if="renderButton"></McrFooter>

            <!-- MODAL FORM PROCESS -->
            <el-dialog
                title="Mcr Form Approval"
                :visible.sync="modalFormProcess"
                width="80%"
                :before-close="handleClose"
                :close-on-click-modal="false">
                <McrAllForm v-if="render"></McrAllForm>
            </el-dialog>

            <!-- MODAL TRACKING  -->
            <modal :show.sync="formTracking.show" size="lg">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('approval_tracking') }}</h5>
                </template>
                <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="formTracking.tracking" v-if="!onLoadTracking">
                    <el-table-column :label="tt('date')" :prop="tt('date')" min-width="100px">
                        <template v-slot="{row}">
                            {{ row.created_at }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('status')" :prop="tt('status')" min-width="100px">
                        <template v-slot="{row}">
                            {{ row.message }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('approver')" :prop="tt('approver')" min-width="100px">
                        <template v-slot="{row}">
                            {{ row.name }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('message')" :prop="tt('message')" min-width="100px">
                        <template v-slot="{row}">
                            {{ row.note }}
                        </template>
                    </el-table-column>
                </el-table>
                <skeleton-loading v-else/>
            </modal>

        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import Api from '@/helpers/api';
    import myTaskDashboard from '@/services/dashboard/myTaskDashboard.service';
    var moment = require('moment');

    import McrAllForm from "../Material/McrAllForm.vue";
    import McrFooter from "../Material/McrFooter.vue";
    import draftList from "@/services/material/draftList.service";

    export default {
        components: {
            McrAllForm,
            McrFooter
        },
        data() {
            return {
                modalFormProcess: false,
                render: false,
                renderButton: false,
                moment: moment,
                params: this.$route.params,
                taskCode: '',
                onLoadTracking:false,
                table: {
                    search: {
                        material_number:'',
                        description:'',
                        requestor:'',
                        equipment_code:'',
                        qty_installed:''
                    },
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 1,
                    },

                },
                onLoad: {
                    table: true
                },
                mcrItem: {},
                multipleKey: [],
                formTracking:{
                    show:false,
                    tracking: []
                }
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.$nextTick(() => {
                this.getMyTask();
            
                if(this.$route.name === 'MCR Form Approval'){
                    setTimeout(() => {
                        this.modalFormProcess = true
                        this.render = true
                    }, 500);
                }

                if (this.$route.name === 'My Task Show') {
                    this.renderButton = true
                }
            })
        },
        watch: {
            $route() {
                if(this.$route.name === 'My Task Show'){
                    this.modalFormProcess = false
                    this.render = false
                    this.renderButton = true
                    this.getMyTask();
                }
            }
        },
        methods: {
            getMyTask(param = '') {
                let context = this;
                
                Api(context, myTaskDashboard.show(context.params.id, this.table.page.current_page, this.table.search)).onSuccess(function(response) {
                    context.taskCode = response.data.data.data.data[0].task_code;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;
                    context.table.total = response.data.data.data.total;
                    context.onLoad.table = false;
                }).onError(function(error) {
                    context.table.data = [];
                    context.table.total = 0;
                    context.onLoad.table = false;
                }).call()
                
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.getMyTask()
                }, 100);
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            format_date(value){
                if (value) {
                   return moment(String(value)).locale('id').format('MMMM Do YYYY, h:mm:ss a')
                }
            },
            handleTableAction(command) {
                if (command.action == 'confirmation') {
                    this.confirmation(command.url)
                } else if (command.action == 'self') {
                    this.$router.push(command.url)
                } else if (command.action == 'modal') {
                    this.$router.push(command.url)

                    setTimeout(() => {
                        this.modalFormProcess = true
                        this.render = false;
                        this.$nextTick(() => {
                            this.render = true;
                            this.renderButton = false;
                        });
                    }, 500);
                } else if (command.action == 'blank') {
                    
                } else if(command.action == 'tracking'){
                     this.tracking(command.data.mcr_code);
                }
            },
            confirmation(url) {
                let context = this;

                context.confirmDialog(context.tt("confirm_process")).then(result => {
                    if (result.value) {
                        Api(context, myTaskDashboard.process(url))
                        .onSuccess(function(response) {
                          context.$notify({
                            message: response.data.message,
                            type: "success",
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                          });
                          
                          context.getMyTask();
                        })
                        .call();
                    }
                });
            },
            handleClose() {
                this.modalFormProcess = false
                this.$router.push('/dashboard/my-task-show/MCRAPPROVAL')
            },
            handleSelectionChange(val) {
                let context = this;

                if(val.length == 0){
                    context.multipleKey = []
                } else {
                    val.forEach(function(element) {
                        context.multipleKey.push(element.key);
                    });
                }
            },
            tracking(mcr_code) {
                this.formTracking.show = true;

                let context = this;
                Api(context, draftList.log_approval(mcr_code))
                .onSuccess(function(response) {
                    context.formTracking.tracking = response.data.data;
                })
                .onError(function(error) {
                    if (error.response.status == 404) {
                      context.formTracking.tracking = [];
                    }
                })
                .onFinish(function() {
                    context.onLoadTracking = false;
                })
                .call();
            },
        }
    };
</script>
